const FdxApiExplorer = (() => {
  const getElements = () => {
    let elements = {}
    let ids = ['explorerOutput', 'fdxUrl', 'duration']
    ids.forEach((id) => {
      elements[id] = {
        set: (value) => {
          document.getElementById(id).innerHTML = value
        },
      }
    })
    return elements
  }

  const clear = () => {
    let elements = getElements()
    Object.keys(elements).forEach((key) => {
      elements[key].set('')
    })
  }

  const runRequest = async () => {
    try {
      let elements = getElements()
      clear()
      let response = await fetch('/fdx/explore', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          endpoint: document.getElementById('endpoint').value,
        }),
      })
      let data = await response.json()
      elements.fdxUrl.set(data.url)
      elements.explorerOutput.set(JSON.stringify(data.payload, null, 2))
      elements.duration.set(data.duration)
    } catch (e) {
      console.log(e)
      let elements = getElements()
      elements.explorerOutput.set(e)
    }
  }

  return { runRequest }
})()

export default FdxApiExplorer
