const DynamicAppRegistration = (() => {
  const apps = (() => {
    const el = () => document.getElementById("applicationCount")
    const total = {
      add:      (num) => { el().innerHTML = parseInt(el().innerHTML) + num },
      subtract: (num) => { el().innerHTML = parseInt(el().innerHTML) - num }
    }
    const remove = (clientId) => {
      const app = document.getElementById(clientId)
      app.parentNode.removeChild(app)
      total.subtract(1)
    }
    return { total, remove }
  })()
  
  const errorMessage = (() => {
    const el = () => document.getElementById("errorMessage")
    return {
      show: (msg) => {
        el().innerHTML = msg
        el().classList.remove("hidden")
      },
      clear: () => {
        el().innerHTML = ""
        el().classList.add("hidden")
      }
    }
  })()

  const refreshMessage = (() => {
    const el = () => document.getElementById("refreshMessage")
    return {
      show: () => el().classList.remove("hidden"),
      hide: () => el().classList.add("hidden")
    }
  })()
  
  const createFakeApp = async (institutionId) => {
    errorMessage.clear()
    let response = await fetch(`/oauth_applications/${institutionId}/fake`, { method: "POST"})
    if (response.ok) {
      apps.total.add(1)
      refreshMessage.show()
    } else {
      errorMessage.show(await response.text())
    }
  }
  const deleteApp = async (clientId) => {
    errorMessage.clear()
    let response = await fetch(`/oauth_applications/${clientId}`, { method: "DELETE"})
    if (response.ok) {
      apps.remove(clientId)
    } else {
      apps.remove(clientId)
      errorMessage.show(await response.text())
    }
  }
  return { createFakeApp, deleteApp }
})()

export default DynamicAppRegistration
